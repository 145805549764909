@tailwind base;
@tailwind components;
@tailwind utilities;
@import './Settings.scss';
@import './Animations.scss';

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}


*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* width */
::-webkit-scrollbar {
  width: 5px;

}

/* Track */
::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #333;
  border:1px solid inherit;


}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html{
  scroll-behavior: smooth;
}



a{
  text-decoration: none;
  color: inherit;
}
.socials{
  transition: all .3s, transform .3s;
}

.socials:hover{
 color:#818178;
 transform: scale(1.3);
}
.glowing-text{
  color: #fff;
  text-shadow:
  0 0 1px #fff,
  0 0 5px #fff,
  0 0 10px #fff,;
}

.swiper-pagination-bullet-active{
  background-color: #fff !important;
}



body {
  background:linear-gradient(198deg, rgb(0, 0, 0) 3%, rgb(64, 91, 103) 25%, #15292b, rgb(159, 178, 185) 100%);
  font-family: "Roboto", sans-serif;
  font-size: $base-size ;
  color: white;
  letter-spacing: 0.7px;
  background-size: 150%;
  animation: bg-animation 10s infinite alternate ease-in-out ;
  overflow-x: hidden;
}

.container {
  margin:  auto;
  padding: 5%;
  width: 100%;
}


button {font-family: "Roboto", sans-serif;
  position: absolute;
  color: $color-main;
  border: $color-main 0.125em solid;
  border-radius: $radius;
  text-shadow: 0 0 0.125em hsla(111, 82%, 33%, 0.3);
  box-shadow: inset 0 0 0.5em 0 $color-main, 0 0 0.5em 0 $color-main;
  background: none;
  cursor: pointer;
  z-index: 2;
  transition: color .3s, background .3s;
  font-weight: bold;
  text-transform: uppercase;
}


button:hover{
  background: $color-main;
  color: black;
}
