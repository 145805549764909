@import '../../Styles/App.scss';

.services {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    overflow: hidden;

    .services-cards {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        font-size:4em;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1em;
    }

    .card {
        position: relative;
        height: 300px;
        perspective: 800px;

        .img {
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
            border-radius: 15px;
            transition: 1s;
          }

          .one {
            background-image: url('../../../public/assets/img/low-poly-grid-haikei.svg');
              box-shadow: 0px 0px 16px -3px#4199ad;
          }
          }

          .text {
            position: absolute;
            inset: 0;
            padding: 30px;
            border-radius: 15px;
            text-align: center;
            transition: 1s;
            opacity: 0;
            background-image: radial-gradient(black, transparent);
            box-shadow: 0px 0px 16px -3px black;
          }

          .text-one {
            transform: rotateX(-180deg);
          }
      }


.card:hover .text {
    transform: rotate(0);
    opacity: 1;
  }
  
  .card:hover .one {
    transform: rotateX(180deg);
    opacity: 0;
  }


  