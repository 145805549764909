@import '../../Styles/App.scss';

.lines{
  z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;

    .line{
      position: absolute;
      width: 1px;
      height: 200%;
      top: 0;
      left: 10%;
      // background: rgba(255, 255, 255, 0.1);
      overflow: hidden;

        &::after{
          content: '';
          display: block;
          position: absolute;
          height: 15vh;
          width: 100%;
          top: -50%;
          left: 5%;
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
          animation: drop 15s 0s infinite forwards;
        }
    }
    .line-one{
      margin-left: 8%;
      &::after{
        animation-delay: 2s;}
    }
    .line-two{
      margin-left: -8%;
      &::after{
        animation-delay: 2.5s}
    }
}




