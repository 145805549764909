@import '../../Styles/App.scss';

.contact {
    position: relative;
    z-index: 1;
    overflow: hidden;
    height: fit-content;


.contact-author{
    background-image: url('../../../public/assets/img/irek2.png');
    background-size: cover;   
    background-repeat: no-repeat;
    }
    .form-box {

        input, textarea{
            background: none;
            border-radius: none;
        }

        input:focus, textarea:focus{
            border-color: transparent; 
            box-shadow: none; 
            outline: none;
        }
      
        .contact_form {
            display: flex;
            flex-direction: column;
            gap: 5em;
            width: 50%;

            button {
                position: relative;
                align-self: center;
                font-family: 'Major Mono Display', monospace;
                color: $color-main;
                border: $color-main 0.125em solid;
                background: none;
                border-radius: $radius;
                text-shadow: 0 0 0.125em hsla(111, 82%, 33%, 0.3);
                box-shadow: inset 0 0 0.5em 0 $color-main, 0 0 0.5em 0 $color-main;
                padding: 1em 2em;
                cursor: pointer;
                z-index: 2;
                transition: color .3s, background .3s;
                font-weight: bold;
            }

            button::after {
                content: "";
                position: absolute;
                inset: 0;
                box-shadow: 0 0 2em 0.5em $color-main;
                opacity: 0;
                background-color: $color-main;
                z-index: -1;
                transition: opacity 0.3s linear;
            }

            button:hover {
                background: $color-main;
                color: black;
            }

            button:hover::after {
                opacity: 0.9;
            }


            label {
                font-size: 2em;
            }


            input,
            textarea {
                max-width: 100%;
                min-width: 100%;
                border: none;
                height: 1.5em;
                color: $color-main;
                padding: 1em;
                background-color: inherit;
                border-bottom: 1px solid $color-main;
                transition: background .3s, box-shadow .3s;
            }

            textarea {
                height: 4em;
            }

            input:focus,
            textarea:focus {
                border-radius: $radius;
                outline: none;
                box-shadow: inset 0 0 0.3em $color-main, 0 0 2em $color-main;
                border: none;
                background: $color-main;
                opacity: 0.8;
                color: black;
            }

        }
    }


    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}