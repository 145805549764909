@import './Settings.scss';
@keyframes bg-animation {
    0% {
          background-position:  0% 0%;
      }
      100% {
          background-position: 100% 100%;
    }
  }

@keyframes drop{
    0%{
      top: -20%
    }
    100%{
      top: 100%
    }
  }

@keyframes text-change  {
    0% { opacity: 0; transform: translateY(-200%);}
    25% { opacity: 1; transform: translateY(20%) }
    55% { opacity: 1; transform: translateY(10%) }
    75% { opacity: 1;transform: translateY(15%) }
    100% { opacity: 0;transform: translateY(-200%);}
}

@keyframes ShowText{
    from{ opacity: 0; margin-bottom: -20%;}
    to{opacity: 1; margin-bottom: 0;}
}