


$color-main: white;
$color-second: #080909;
$color-gradient:  linear-gradient(320deg, #080808FF 0%, #0A2C27FF 100%);
$color-gradientTwo: linear-gradient(359deg, #080808FF 0%, #071714FF 100%);

$font-text: 'Major Mono Display', monospace;

$radius: 5px;
$base-size: 10px;


