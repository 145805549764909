@import '../../Styles/App.scss';




.nav__bar {
    position: fixed;
    display: flex;
    opacity: 0;
    align-items: center;
    justify-content: space-between;
    top: 0;
    width: 100vw;
    padding: 1em 2em;
    backdrop-filter: blur(1em);
    background: inherit;
    z-index: 10;
    border-radius: $radius;
    animation: ShowText 3s .2s forwards;
    

  

    .nav__bar-author{
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s;

        .author{
            FONT-SIZE: 22px;
            text-shadow:  0 2px #8b9adb, 0 0 1px #5f6eb9, 0 0 10px #4a91c3;
            color: #b1b0b4;
            font-weight:bold;
        }
    }

    .nav__bar-author:hover{
        text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff;

    }

    .nav__bar-links {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
        z-index: 12;


        .nav__link {
            position: relative;
            font-size: 1.1em;
            padding: 1em;
            transition: transform .3s, color .3s;
            z-index: 20;

        }
        .nav__link:hover{
            transform:scale(1.2);
            color:azure;
        }

        .nav__link::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            bottom: 40%;
            left: 50%;
            background: rgb(255, 255, 255);
            transform: translate(-50%, -50%);
            z-index: 19;
            box-shadow: 0 0 50px 15px #48abe0;
            transition: width .3s ease-in-out;

        }

        .nav__link:hover::before {
            width: 100%;
            height: 2px;
            bottom:20%

        }



    }

    .nav__burger-icon {
        display: none;
        cursor: pointer;
        padding: 1em;
        z-index: 100;




        &.close .burger-icon-line::after {
            opacity: 0;
        }

        &.close .burger-icon-line {
            transform: rotate(45deg);
            width: 2em;
        }

        &.close .burger-icon-line::before {
            transform: rotate(-95deg);
            top: 0;
            width: 2em;
        }

        .burger-icon-line {
            display: flex;
            justify-content: center;
            position: relative;
            height: .2em;
            width: 1.3em;
            background: $color-main;
            border-radius: 2px;
            transition: width .3s, transform .3s;

            &::after {
                position: absolute;
                top: 10px;
                content: '';
                height: .2em;
                width: 0.8em;
                background: $color-main;
                transition: width .3s, opacity .3s;

            }

            &::before {
                position: absolute;
                top: -10px;
                content: '';
                height: .2em;
                width: 1.1em;
                background: $color-main;
                transition: width .3s, transform .3s, top .3s;
            }

        }
    }
}

.nav__burger-icon:hover .burger-icon-line::before {
    width: 2em;
}

.nav__burger-icon:hover .burger-icon-line::after {
    width: 2em;
}

.nav__burger-icon:hover .burger-icon-line {
    width: 2em;
}