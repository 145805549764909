header {
    height: 80vh;
    margin-bottom: 15%;

}

.title-box {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: start;
    justify-content: center;


    .web-title {
        opacity: 0;
        font-size: 5em;
        color: #fff;
        text-shadow: 0 0 2px #fff, 0 0 1px #fff, 0 0 10px #fff;;
        line-height: 1.5em;
        animation: ShowText 1.5s .3s forwards;
        transition: opacity;
    }
    .web-title-description{
        font-size: 1.5em;
    }
}



// Header.scss

.scroll-container {
    overflow: hidden; // Hide the overflow to create a scrolling effect
    position: relative; // Position relative for the absolute child
    height: 2rem; // Set a fixed height based on your text size
}

.scroll-text {
    display: flex; // Use flex to allow horizontal scrolling
    animation: scroll 30s linear infinite; // Define the animation duration and type
    white-space: nowrap; // Prevent line breaks for scrolling
}

@keyframes scroll {
    0% {
        transform: translateX(0); // Start position
    }
    100% {
        transform: translateX(-50%); // Move to the left by half the total width of the content
    }
}
