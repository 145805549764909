@import '../../Styles/App.scss';

.calendly-hover {
    cursor: pointer;
    position: relative;
   
  }
   
  .calendly-link {
    height: 20px;
    position: absolute;
    transition: 0.3s ease;
    overflow: hidden;
  }
